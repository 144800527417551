<template>
  <div class="app-info-page">

    <div class="info-block">
      <div class="app-info-metrics">
        <div class="table-info-block-wrap">
          <div class="table-info-block">
            <div class="details-block">
              <div class="amount">
                <template v-if="appInfoDataLoaded">
                  {{ appInfoData?.rating }}
                </template>
                <template v-else>
                  <div class="skeleton total-skeleton"></div>
                </template>
              </div>
              <div class="title">App Rating</div>
            </div>
            <div class="icon-block">
              <img src="@/assets/svg/rating.svg" alt="App Rating icon">
            </div>
          </div>
        </div>
        <div class="table-info-block-wrap">
          <div class="table-info-block">
            <div class="details-block">
              <div class="amount">
                <template v-if="appInfoDataLoaded">
                  {{ appInfoData?.update_freq_days }}
                </template>
                <template v-else>
                  <div class="skeleton total-skeleton"></div>
                </template>
              </div>
              <div class="title">
                <span class="desktop-visible tablet-hidden mobile-visible">
                  Update Frequency (d)
                </span>
                <span class="tablet-visible">Update freq.</span>
              </div>
            </div>
            <div class="icon-block">
              <img src="@/assets/svg/update.svg" alt="Update Frequency icon">
            </div>
          </div>
        </div>
        <div class="table-info-block-wrap">
          <div class="table-info-block">
            <div class="details-block">
              <div class="amount">
                <template v-if="appInfoDataLoaded">
                  {{ appInfoData.age_days ? convertDays(appInfoData.age_days, 'years') : '' }}
                </template>
                <template v-else>
                  <div class="skeleton total-skeleton"></div>
                </template>
              </div>
              <div class="title">App Age</div>
            </div>
            <div class="icon-block">
              <img src="@/assets/svg/app-age.svg" alt="App Age icon">
            </div>
          </div>
        </div>
      </div>
      <div class="additional-information">
        <template v-if="appInfoDataLoaded">
          <div class="block-heading">
            <div class="title">Additional Information</div>
            <div class="deleted-app-text"
                 v-if="appInfoData?.deleted_at">😢
              <span>Deleted from App Store</span>
            </div>
          </div>
          <div class="information-table">
            <table>
              <tbody>
              <tr>
                <td class="first-col">Rating:</td>
                <td>
                  <div class="rating">
                    <div class="css-icons star-icon"></div>
                    {{ appInfoData?.rating }}
                  </div>
                </td>
              </tr>

              <tr>
                <td class="first-col">Voted:</td>
                <td>
                  <div class="inner-content">
                    {{ shortUnits(appInfoData?.voted, 2) }}
                  </div>
                </td>
              </tr>

              <tr>
                <td class="first-col">App Store Link:</td>
                <td>
                  <template v-if="!currentApp?.store_link">-</template>
                  <template v-else>
                    <template v-if="currentApp?.store?.key !== 'APP_STORE'">
                      <a :href="currentApp?.store_link"
                         target="_blank"
                         rel="nofollow">{{ currentApp?.origin_id }}</a>
                    </template>
                    <template v-else>
                      <a :href="currentApp?.store_link"
                         target="_blank"
                         rel="nofollow">id{{ currentApp?.origin_id }}</a>
                    </template>
                  </template>
                </td>
              </tr>

              <tr>
                <td class="first-col">Website:</td>
                <td>
                  <template v-if="!appInfoData?.site">-</template>
                  <template v-else>
                    <div class="inner-content">
                      <a :href="appInfoData.site">{{ appInfoData.site }}</a>
                    </div>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="first-col">Email:</td>
                <td>
                  <div class="inner-content">
                    {{ appInfoData?.email }}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="first-col">Privacy Policy:</td>
                <td>
                  <template v-if="!appInfoData?.policy">-</template>
                  <template v-else>
                    <div class="inner-content">
                      <a :href="appInfoData.site">{{ appInfoData.policy }}</a>
                    </div>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="first-col">Categories:</td>
                <td>
                  <div class="inner-content">
                    <template v-for="(category, idx) in appInfoData?.categories">
                      <template v-if="idx !== 0">,&nbsp;</template>
                      <span class="category-item">{{ category?.title }}</span>
                    </template>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="first-col">Size:</td>
                <td>
                  <template v-if="appInfoData?.size_mb && appInfoData?.size_mb !== 0">
                    {{ formatAmountNumbers(appInfoData?.size_mb) }}MB
                  </template>
                  <template v-else>-</template>
                </td>
              </tr>
              <tr>
                <td class="first-col">IAP:</td>
                <td>
                  <template v-if="appInfoData?.IAP?.from && appInfoData?.IAP?.to">
                    <span>${{ appInfoData?.IAP?.from }}</span> - <span>${{ appInfoData?.IAP?.to }}</span>
                  </template>
                  <template v-else>-</template>
                </td>
              </tr>
              <tr v-if="currentApp?.store?.key !== 'APP_STORE'">
                <td class="first-col">Installs:</td>
                <td>
                  {{ formatAmountNumbers(appInfoData?.installs) }}
                </td>
              </tr>
              <tr>
                <td class="first-col">Release Date:</td>
                <td>
                  {{ formatItemDate(appInfoData?.release_date) }}
                </td>
              </tr>
              <tr>
                <td class="first-col">Last Update:</td>
                <td>
                  {{ formatItemDate(appInfoData?.update_date) }}
                </td>
              </tr>
              <tr>
                <td class="first-col">Version:</td>
                <td>
                  {{ appInfoData?.last_version }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else>
          <div class="preloader-wrap">
            <preloader-table-small loader-size="50px"></preloader-table-small>
          </div>
        </template>
      </div>
    </div>

    <div class="history-block">
      <div class="version-history">
        <template v-if="historyDataLoaded">
          <div class="block-heading">
            <div class="title">Version history</div>
          </div>

          <div class="version-history-block-wrap">
            <div class="version-history-block">
              <template v-if="historyData?.length > 0">
                <div class="version-content">
                  <div class="version-history-item"
                       v-for="item in historyData">
                    <div class="history-heading">
                      <div class="version">
                        {{ item?.version }}
                      </div>
                      <div class="date">
                        {{ item?.date }}
                      </div>
                    </div>
                    <div class="history-release-notes">
                      {{ item?.note }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="show-more-btn"
                 v-if="showViewMoreBtn"
                 @click="openModal('showHistoryModal')">
              <div class="text">View more</div>
              <svg-icon icon="arrow-right"/>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="preloader-wrap">
            <preloader-table-small loader-size="50px"></preloader-table-small>
          </div>
        </template>
      </div>
    </div>

    <custom-modal :opened="showHistoryModal"
                  :max-width="1000"
                  class-name="history-list-modal"
                  @closeModal="closeModal('showHistoryModal')">
      <div class="modal-message textarea-block">
        <div class="version-history-block">
          <div class="version-history-item"
               v-for="item in historyData">
            <div class="history-heading">
              <div class="version">
                {{ item?.version }}
              </div>
              <div class="date">
                {{ item?.date }}
              </div>
            </div>
            <div class="history-release-notes">
              {{ item?.note }}
            </div>
          </div>
        </div>
      </div>
    </custom-modal>

<!--    <the-improvement-block/>-->
  </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance, computed, watch} from 'vue';
import {httpRequest} from "@/api";
import {useStore} from 'vuex';
import {formatDate, convertDays, shortUnits, getCookie} from "@/utils/utils";
import DataTable from "@/views/DashboardPage/components/DataTable";
import {dashboardVisibilityTableConfig} from "@/configs/page-tables-configs/dashboard-visibility.js";

import BaseButton from "@/components/UI/BaseButton";

export default {
  name: "AppInfo",
  props: {
    currentKeywordCountryCode: {
      type: String,
    },
  },
  components: {
    BaseButton,
    DataTable,
  },
  setup() {
    const {proxy} = getCurrentInstance();
    let currentAppId = getCookie('currentAppId');
    const apiUrls = proxy?.$ApiUrls;
    const dashboardVisibilityHeaders = dashboardVisibilityTableConfig.headers;
    const store = useStore();

    const isMobile = computed(() => store.getters.isMobile);
    const pageMeta = computed(() => store.getters.pageMeta);
    const currentApp = computed(() => store.getters.currentApp);
    const currentCountry = computed(() => store.getters.currentCountry);
    const currentCountryManual = computed(() => store.getters.currentCountryManual);

    let url = '';

    let showHistoryModal = ref(false);
    let appInfoDataLoaded = ref(false);
    let historyDataLoaded = ref(false);
    let showViewMoreBtn = ref(false);

    let appInfoData = ref({});
    let historyData = ref([]);

    watch([currentCountryManual, currentApp, currentCountry], async function (newValues, oldValues) {
      if (JSON.stringify(newValues[0]) !== JSON.stringify(oldValues[0])) {
        url = '?country_code=' + newValues[0]?.code + '&app_id=' + currentAppId;
        await resetPageValues(url);
      }

      if (JSON.stringify(newValues[0]) === JSON.stringify(oldValues[0]) && currentAppId !== newValues[1]?.id && newValues[1]?.id !== oldValues[1]?.id) {
        currentAppId = newValues[1]?.id;
        url = '?country_code=' + newValues[2]?.code + '&app_id=' + currentAppId;
        await resetPageValues(url);
      }
    });

    if (Object.values(currentCountryManual.value)?.length === 0) {
      url = '?country_code=' + currentCountry?.value?.code + '&app_id=' + currentAppId;
    } else {
      url = '?country_code=' + currentCountryManual.value?.code + '&app_id=' + currentAppId;
    }

    async function resetPageValues(url) {
      appInfoDataLoaded.value = false;
      historyDataLoaded.value = false;

      appInfoData.value = {};
      historyData.value = [];

      await fetchAppInfoBlock(url);
      await fetchAppHistoryBlock(url);
    }

    function formatAmountNumbers(value) {
      return value?.toLocaleString('ru-RU', {maximumFractionDigits: 0});
    }

    function formatItemDate(val) {
      if (val) {
        return formatDate(val, 'month-day-year');
      }
      return '';
    }

    async function fetchAppInfoBlock(url) {
      appInfoDataLoaded.value = false;
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.dashboard.APP_INFO_BLOCK + url);

      if (!result.hasOwnProperty('errors') && JSON.stringify(result) !== '{}' && JSON.stringify(result) !== '[]') {
        appInfoData.value = {...result};
      }

      appInfoDataLoaded.value = true;
    }

    async function fetchAppHistoryBlock(url) {
      historyDataLoaded.value = false;
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.dashboard.VERSION_HISTORY_BLOCK + url);

      if (!result.hasOwnProperty('errors') && JSON.stringify(result) !== '{}' && JSON.stringify(result) !== '[]') {
        historyData.value = [...result];
      }

      historyDataLoaded.value = true;
    }

    function detectViewMoreBtn() {
      showViewMoreBtn.value = document.querySelector('.version-content')?.clientHeight > document.querySelector('.version-history-block')?.clientHeight;
    }

    onMounted(async function () {
      await fetchAppInfoBlock(url);
      await fetchAppHistoryBlock(url);

      detectViewMoreBtn();

      appInfoDataLoaded.value = true;
      historyDataLoaded.value = true;
    });

    return {
      dashboardVisibilityHeaders,
      formatAmountNumbers,
      appInfoData,
      historyData,
      appInfoDataLoaded,
      historyDataLoaded,
      pageMeta,
      isMobile,
      currentApp,
      convertDays,
      formatItemDate,
      shortUnits,
      showHistoryModal,
      showViewMoreBtn,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>