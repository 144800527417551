export const dashboardVisibilityTableConfig = {
    headers: [
        {
            text: 'Country',
            value: 'country_code',
            align: 'left',
            width: '27%',
            textWidth: '250px',
            sortable: false,
        },
        {
            text: 'Top',
            value: 'top',
            width: '30%',
            textWidth: '25%',
            align: 'left',
            sortable: false,
            headingClass: 'no-right-padding'
        },
        {
            text: 'Category',
            value: 'category',
            width: '22%',
            textWidth: '25%',
            align: 'left',
            sortable: false,
            headingClass: 'no-right-padding'
        },
        {
            text: 'Position',
            value: 'rank',
            width: '18%',
            textWidth: '25%',
            align: 'right',
            sortable: false,
        },
    ],
}